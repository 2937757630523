import { Button, Modal, Upload } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import React from "react";
import { v4_confirmUpload, v4_uploadAccountingCosts } from "../../../routing/v4_Routes/projectManagement.routes";
import { displayErrorNotification } from "../../../utils/messageServices/notificationService";
import _ from "lodash";
import { UploadCheckTableDataModal } from "./UploadCheckTableData.modal";
import { ReAuthenticationFormModal } from "../../security/ReAuthenticationForm.modal";


export function UploadCostFileModal(props) {

    const [loading, setLoading] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [fileList, setFileList] = React.useState([]);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [data, setData] = React.useState([]);
    
    const handleUpload = async () => {
        setUploading(true);
        setLoading(true);

        if (fileList.length === 0) {
            setLoading(false);
            setUploading(false);
            return;
        };

        const response = await v4_uploadAccountingCosts(fileList[0]);

        if (!_.isEmpty(response)) {
            setFileList([]);
            setData(response);
            setLoading(false);
            setUploading(false);
        } else {
            displayErrorNotification("Fehler beim Hochladen der Datei. Bitte versuchen Sie es erneut.");
            setLoading(false);
            setUploading(false);
            props.OnCancel();
        };


    };

    const confirm = async (data) => {
        setLoading(true);

        await v4_confirmUpload(data);
        setLoading(false);
    };

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        maxCount: 1,
        accept: ".xlsx"
    };

    return (
        <>
            <Modal
                title="Kosten hochladen"
                open={props.Open}
                onCancel={props.OnCancel}
                footer={[
                    <Button key="back" onClick={props.OnCancel}>
                        Abbrechen
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleUpload}>
                        Hochladen
                    </Button>,
                ]}
                maskClosable={false}
            >
                <Upload {...uploadProps}>
                    <Button
                        type="primary"
                        loading={loading || uploading}
                        icon={uploading ? <LoadingOutlined /> : <UploadOutlined />}
                    >Kostentabelle hochladen</Button>
                </Upload>
            </Modal>

            <UploadCheckTableDataModal Open={data?.length > 0} Data={data} OnCancel={() => {
                setFileList([]);
                setData([]);
            }} OnConfirm={
                async () => {
                await confirm(data);
                setData([]);
                setFileList([]);
                props.OnCancel();
            }
            } />

        </>
    )
}