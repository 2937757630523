import { displayInfoNotification } from "../utils/messageServices/notificationService";

// WebSocket-Verbindung erstellen
export const connectWebSocket = () => {
    if (!process.env.REACT_APP_WS_URL) {
        console.error('REACT_APP_WS_URL nicht definiert');
        return;
    };

    const ws = new WebSocket(process.env.REACT_APP_WS_URL);
    
    // Verbindung hergestellt
    ws.onopen = () => {
        console.log('WebSocket verbunden');
        displayInfoNotification('WebSocket verbunden');
    };
    
    // Nachrichten empfangen
    ws.onmessage = (event) => {
        console.log('Nachricht empfangen:', event.data);
    };
    
    // Fehlerbehandlung
    ws.onerror = (error) => {
        console.error('WebSocket Fehler:', error);
    };
    
    // Verbindung geschlossen
    ws.onclose = () => {
        console.log('WebSocket geschlossen');
        // Automatischer Reconnect nach 5 Sekunden
        setTimeout(() => {
            console.log('Versuche Neuverbindung...');
            connectWebSocket(process.env.REACT_APP_WS_URL);
        }, 5000);
    };

    return ws;
};

// WebSocket-Verbindung schließen
export const closeWebSocket = (ws) => {
    ws.close();
};

// Nachricht senden
export const sendMessage = (ws, message) => {
    ws.send(message);
};