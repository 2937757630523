import { Alert, Button, Divider, Flex, List, Popconfirm, Space, Statistic, Table, Typography } from "antd";
import React, { useEffect, useMemo, useCallback } from "react";
import { BugOutlined } from "@ant-design/icons";
import { db, getCollectionData } from "../../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { addListenerToPath } from "../../../listener/firebaseSnapshotListener";
import { v4_delete_substitute, v4_drop_substitutes, v4_get_substitute_count, v4_get_substitutes, v4_reimport_substitutes } from "../../../routing/v4_Routes/stream.routes";
import _ from "lodash";
import { displayInfoMessage } from "../../../utils/messageServices/messageService";
import { doc, deleteDoc } from "firebase/firestore";

export function DataIssuePage() {

    const navigate = useNavigate();
    const { _id } = useParams();
    const [data, setData] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);

    const getData = async () => {
        setLoading(true);
        try {
            const c = await v4_get_substitute_count(_id);
            console.log(c);
            setCount(c);
            const docs = await v4_get_substitutes(_id, page, pageSize);
            setData(docs);
        } catch (error) {
            console.error("Fehler beim Laden der Daten:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (_id) {  // Nur ausführen wenn _id existiert
            getData();
        }
    }, [_id]); // Nur _id als Dependency

    // 2. Separate Funktion für Pagination
    const getDataByPagination = async (newPage, newPageSize) => {
        try {
            setLoading(true);
            const c = await v4_get_substitute_count(_id);
            setCount(c);
            const docs = await v4_get_substitutes(_id, newPage, newPageSize);
            setData(docs);
            setPage(newPage);
            setPageSize(newPageSize);
        } catch (error) {
            console.error("Fehler beim Laden der Daten:", error);
        } finally {
            setLoading(false);
        }
    };

    // 3. Die anderen Funktionen anpassen
    const dropSubstitutes = async () => {
        try {
            setLoading(true);
            await v4_drop_substitutes(_id);
            await getDataByPagination(1, pageSize);
            
        } catch (error) {
            console.error("Fehler beim Löschen:", error);
        } finally {
            try {
                //Wait 3 seconds
                await new Promise(resolve => setTimeout(resolve, 3000));
                const docRef = doc(db, "substitutes", _id);
                await deleteDoc(docRef);
            } catch (error) {
                console.error("Fehler beim Löschen:", error);
            }
            setLoading(false);
        }
    };

    const deleteDocumentById = async (id) => {
        try {
            setLoading(true);
            await v4_delete_substitute(id);
            // Aktuelle Seite neu laden
            await getDataByPagination(page, pageSize);
        } catch (error) {
            console.error("Fehler beim Löschen:", error);
        }
    };

    const reimportData = async () => {
        setLoading(true);
        //delete document
        const docRef = doc(db, "substitutes", _id);
        await deleteDoc(docRef);
        //wait 3 seconds
        await new Promise(resolve => setTimeout(resolve, 3000));
        await v4_reimport_substitutes(_id);
        await getData();
    };

    // Optimierte Spaltengenerierung mit useMemo
    const additionalColumns = useMemo(() => {
        if (_.isEmpty(data)) return [];

        const cols = Object.keys(data[0]).map((key) => ({
            title: key,
            dataIndex: key,
            key: key,
        }));

        return [...cols, {
            title: 'Aktion',
            key: 'action',
            render: (text, record) => (
                <Flex gap='small'>
                    <Button onClick={() => deleteDocumentById(record._id)} type="primary" danger>Löschen</Button>
                </Flex>
            ),
        }];
    }, [data]);

    // Firebase Listener
    // useEffect(() => {
    //     const unsubscribe = addListenerToPath(`substitutes/${_id}`, () => {
    //         displayInfoMessage("Daten wurden aktualisiert.");
    //     }, (error) => {
    //         console.error("Fehler beim Laden der Datenprobleme:", error);
    //     });

    //     return () => unsubscribe();
    // }, [_id]);

    return (
        <div>
            <div>
                <Flex justify="center" gap='small'>
                    <BugOutlined style={{ fontSize: "50px" }} />
                    <Typography.Title level={2}>Datenprobleme</Typography.Title>
                </Flex>
            </div>

            <div>
                <Divider orientation="left">
                    Übersicht
                </Divider>
            </div>

            <div style={{
                textAlign: 'center'
            }}>
                <Space direction="vertical">
                <Statistic
                    title="Anzahl der Probleme"
                    value={count}
                    prefix={<BugOutlined />}
                    suffix="Zeilen"
                />
                <Space>
                <Popconfirm
                    title="Möchten Sie wirklich alle Probleme löschen? Diese Aktion kann nicht rückgängig gemacht werden. (nie wieder -> was weg ist, ist weg)"
                    onConfirm={dropSubstitutes}
                    okText="Ja"
                    cancelText="Nein"
                >
                    <Button type="primary" danger>Für immer Löschen</Button>
                </Popconfirm>
                <Popconfirm
                    title="Möchten Sie wirklich die Daten neu importieren? Bei einem erneuten Import werden alle Daten gelöscht und neu importiert. Die Probleme mit zuordnungen sollten vorher behoben werden."
                    onConfirm={reimportData}
                    okText="Ja"
                    cancelText="Nein"
                >
                    <Button type="primary">Daten neu importieren</Button>
                </Popconfirm>
                </Space>
                </Space>
            </div>

            <div>
                <Divider orientation="left">
                    Daten die Probleme verursachen
                </Divider>
            </div>

            <div style={{
                textAlign: 'left'
            }}>
                <Table
                    columns={additionalColumns}
                    dataSource={data}
                    loading={loading}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: count,
                        onChange: (page, pageSize) => {
                            setPage(page);
                            setPageSize(pageSize);
                            getDataByPagination(page, pageSize);
                        },
                    }}
                    scroll={{ x: 'max-content' }}
                />

            </div>
        </div>
    );
};