import axios from "axios";
import { auth } from "../../firebase";
import { v4_setAPI } from "../api";
import { displaySuccessNotification } from "../../utils/messageServices/notificationService";
import _ from "lodash";
import { axiosInstance } from "../../axios/axiosInstance";
import { displayErrorMessage } from "../../utils/messageServices/messageService";
import { displayErrorModal } from "../../utils/messageServices/modalService";

const API_URL = v4_setAPI();

/**
 * 
 * @param {File} file 
 * @returns 
 */
export async function v4_uploadCSV(file, _id, replaceHeaders = false, changeEncoding = false, encoding = "utf-8", uid = null, overwriteFile = false) {
    try {
        let response = null;
        const formData = new FormData();
        formData.append("file", file);
        if (!replaceHeaders) {
            response = await axiosInstance.post(`${API_URL}/import/${_id}/upload`, formData, { params: { overwriteEncoding: changeEncoding, encodingParam: encoding, uid: uid, overwriteFile: overwriteFile }, headers: {
            'Content-Type': 'multipart/form-data'
        } });
        } else {
            response = await axiosInstance.post(`${API_URL}/import/${_id}/upload/replace-headers`, formData, { params: { overwriteEncoding: changeEncoding, encodingParam: encoding, uid: uid, overwriteFile: overwriteFile }, headers: {
            'Content-Type': 'multipart/form-data'
        } });
        };
        console.log(response);
        return response?.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
        return null;
    };
};

/**
 * Confirms the CSV upload by sending a POST request to the server.
 *
 * @param {Object} data - The data to be confirmed.
 * @param {string} _id - The ID of the import process.
 * @param {string} uid - The user ID.
 * @returns {Promise<boolean|null>} - Returns true if the request is successful, otherwise returns null.
 * @throws Will throw an error if the request fails.
 */
export async function v4_confirm_csv_upload(data, _id, uid) {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.post(`${API_URL}/import/${_id}/confirm/${uid}`, { data: data }, { headers: { Authorization: `Bearer ${token}` } });
        displaySuccessNotification(`Status: ${response.status} - ${response.statusText}`);
        return true;
    } catch (error) {
        console.error(error);
        displayErrorModal("Error", error?.response?.data?.error, error?.response?.data?.stack);
        return null;
    };
};