import { Avatar, Button, Descriptions, Space, Typography } from "antd";
import React, { useState } from "react";
import { auth } from "../../../firebase";
import { updateProfile, updateEmail} from "firebase/auth";
import { displayErrorMessage, displayInfoMessage } from "../../../utils/messageServices/messageService";
import { formatPhoneNumber } from "../../../utils/helpers/parsePhoneNumber.helper.js";
import { checkEmailValidity } from "../../../utils/helpers/defaultUtils.utils.mjs";
import { displaySuccessNotification } from "../../../utils/messageServices/notificationService";
import { ReAuthButtonModal } from "../../security/ReAuth.button.component";
import { v4_update_user_details } from "../../../routing/v4_Routes/user.routes";
import { MultiFactorModal } from "../../security/MultiFactor.component";

const { Item } = Descriptions;
const { Text } = Typography;

export function AccountSecurityPage() {

    const user = auth.currentUser;
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || "Keine Telefonnummer hinterlegt");
    const [email, setEmail] = useState(user?.email || "FEHLER: E-Mail konnte nicht geladen werden");
    const [avatar, setAvatar] = useState(user?.photoURL || null);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const updateFirebaseUser = async (action, payload) => {
        const user = auth.currentUser;
        try {
            switch (action) {
                case "email":
                    if (!checkEmailValidity(payload)) {
                        throw new Error("Ungültige E-Mail-Adresse. Bitte überprüfen Sie Ihre Eingabe.");
                    };
                    await updateEmail(user, payload);
                    break;
                case "phone":
                    const formattedPhone = formatPhoneNumber(payload);
                    if (formattedPhone === null) {
                        throw new Error("Ungültige Telefonnummer. Bitte Format beachten: --> +491234567890");
                    };
                    const u = await v4_update_user_details(user.uid, { phoneNumber: formattedPhone });
                    console.log(u);
                    setPhoneNumber(formattedPhone);
                    break;
                case "avatar":
                    await updateProfile(user, {
                        photoURL: payload
                    });
                    break;
                default:
                    break;
            }
            displaySuccessNotification('Benutzer erfolgreich aktualisiert');
        } catch (error) {
            console.error("Fehler beim Aktualisieren des Benutzers:", error);
            displayErrorMessage('Fehler beim Aktualisieren des Benutzers', error.message);
        }
    };

    return (
        <div>
            <div style={{
                alignContent: "left",
                alignItems: "left",
                justifyContent: "left",
                display: "flex",
                marginBottom: 20
            }}>
                <Space>
                    <ReAuthButtonModal title="Sicherheitseinstellungen ändern" text="Einstellungen ändern" OnSuccess={() => setEditMode(true)} />
                </Space>
            </div>

            <Descriptions column={1} bordered>
                <Item label="Avatar">
                    <Button
                        type="icon"
                        icon={<Avatar
                            src={avatar || "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8UG9ydHIlQzMlQTR0fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"}
                            alt="User"
                            sx={{ width: 150, height: 150 }}
                        />}
                        onClick={() => displayInfoMessage("Avatar ändern", "Diese Funktion ist noch nicht verfügbar.")}
                    />
                </Item>

                <Item label="E-Mail">
                    {editMode ? <>
                        <Text editable={{
                            onChange: async (value) => {
                                if (!checkEmailValidity(value)) {
                                    displayErrorMessage("Ungültige E-Mail-Adresse", "Bitte überprüfen Sie Ihre Eingabe.");
                                    return;
                                };
                                await updateFirebaseUser("email", value);
                                setEmail(value);
                            },
                            icon: <Button type="primary" size="small">Aktualisieren</Button>
                        }}>
                            {email}
                        </Text>
                    </> : email}
                </Item>

                <Item label="Telefonnummer">
                    {editMode ? <>
                        <Text editable={{
                            onChange: async (value) => {
                                const ph = formatPhoneNumber(value);
                                if (ph === null) {
                                    displayErrorMessage("Ungültige Telefonnummer", "Bitte überprüfen Sie Ihre Eingabe.");
                                    return;
                                };
                                updateFirebaseUser("phone", ph);
                            },
                            icon: <Button type="primary" size="small">Aktualisieren</Button>
                        }}>
                            {phoneNumber}
                        </Text>
                    </> : phoneNumber}
                </Item>

                <Item label="Letzter Login">
                    {user?.metadata?.lastSignInTime}
                </Item>

            </Descriptions>
            <MultiFactorModal />
        </div>
    );
};