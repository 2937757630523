import { Alert, Button, Divider, Flex, List, Typography } from "antd";
import React, { useEffect } from "react";
import { BugOutlined } from "@ant-design/icons";
import { getCollectionData } from "../../../firebase";
import { useNavigate } from "react-router-dom";
// import { addListenerToPath } from "../../../listener/firebaseSnapshotListener";

export function DataIssuesPage() {

    const navigate = useNavigate();
    const [problems, setProblems] = React.useState([]);

    const getData = async () => {
        const docs = await getCollectionData('substitutes');
        console.log(docs);
        setProblems(docs);
    };

    const navigateToProblem = (id) => {
        navigate(`/manage/projects/dataissues/project/${id}`);
    };

    useEffect(() => {
        getData();

        return () => {
            setProblems([]);
        };
    }, []);


    // useEffect(() => {
    //     const unsubscribe = addListenerToPath('substitutes', (data) => {
    //         setProblems(data);
    //     }, (error) => {
    //         console.error("Fehler beim Laden der Datenprobleme:", error);
    //     });

    //     return () => {
    //         unsubscribe();
    //     };
    // }, []);

    return (
        <div>
            <div>
                <Flex justify="center" gap='small'>
                    <BugOutlined style={{ fontSize: "50px" }} />
                    <Typography.Title level={2}>Datenprobleme</Typography.Title>
                </Flex>
            </div>

            <div>
                <Divider orientation="left">
                    Übersicht
                </Divider>
            </div>
            <div style={{
                textAlign: 'left'
            }}>
                <List
                    itemLayout="horizontal"
                    dataSource={problems}
                    renderItem={item => (
                        <List.Item actions={[
                            <Button type="icon"
                            onClick={() => navigateToProblem(item?.id)}
                            >Jetzt lösen</Button>
                        ]}>
                            <List.Item.Meta
                                title={<>Problem Projekt: {item?.id}</>}
                                description={
                                    <Alert
                                        message={<>Problem: {item?.problem}</>}
                                        description=<div>
                                            <Typography.Text>
                                                Es sind Probleme bei der Verarbeitung der Daten aufgetreten. Bitte überprüfen Sie die Daten und korrigieren Sie die Fehler.<br />
                                                Status: {item?.info}<br />
                                                Einsatz: Die Ersatzdatenbank wird eingesetzt, wenn daten nicht zugeordnet werden können.<br />
                                                Auflösung: Beheben Sie die Fehler in den Daten, um die Verarbeitung zu ermöglichen.<br />
                                            </Typography.Text>
                                        </div>
                                        type="error"
                                        showIcon
                                    />
                                }
                            />
                        </List.Item>
                    )}

                    bordered
                    header={<div>Wir haben folgende Datenprobleme erkannt:</div>}
                    footer={<Typography.Text>Erkannte Datenprobleme werden nicht automatisch gelöst.</Typography.Text>}
                    pagination={{
                        pageSize: 10
                    }}
                />
            </div>
        </div>
    );
};