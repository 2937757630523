import { Button, DatePicker, Form, Modal } from "antd";
import React from "react";
import { v4_startTimeboardCalculation } from "../../../routing/v4_Routes/admin.routes.js";



export function CalculateV2PersonalCostsModal(props) {
    const [loading, setLoading] = React.useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        const response = await v4_startTimeboardCalculation(values);
        console.log(response);
        setLoading(false);
        props.onClose();
    };

    return (
        <Modal
        open={props.open}
        onCancel={() => props.onClose()}
        onClose={() => props.onClose()}
        title="Kostenberechnung"
        footer={null}
        width="80%"
        destroyOnClose={true}
        loading={loading}
        >
            <Form
            layout="vertical"
            onFinish={onFinish}
            >
                <Form.Item
                label="Startdatum"
                name="startDate"
                rules={[{ required: true, message: "Bitte geben Sie ein Startdatum ein!" }]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                label="Enddatum"
                name="endDate"
                rules={[{ required: true, message: "Bitte geben Sie ein Enddatum ein!" }]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Berechnen</Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};