import { Typography, Row, Col, Descriptions, Button, List, Result } from "antd";
import { AnimateSharedLayout } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { v4_getCsvSchemaData } from "../../../routing/v4_Routes/project.routes";
import { delay } from "lodash";
import { UploadCSVFileModal } from "../../modals/csvUpload/UploadCSVFile.modal";

const { Title, Text } = Typography;

export function CSVUploadPage() {

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const project = useSelector((state) => state.projectState.selectedProject);
    const [available, setAvailability] = useState(false);
    const [schemaDetails, setSchemaDetails] = React.useState({});
    const [fields, setFields] = React.useState([{}]);
    const [loading, setLoading] = React.useState(false);
    const [showUploadModal, setShowUploadModal] = React.useState(false);

    async function fetchCsvSchemaData() {
        setLoading(true);
        const data = await v4_getCsvSchemaData(project?._id);
        setSchemaDetails(data);
        setFields(data?.fields || [{}]);
        setLoading(false);

        if (data?.fields?.length > 0) {
            setAvailability(true);
        } else {
            // navigate(`/manage/projects/csv/schema/${project?._id}`); Würde aber auch den Benutzer zurück auf die Schema Seite leiten, was nicht gewünscht ist...
            setAvailability(false);
        }
    };

    useEffect(() => {
        fetchCsvSchemaData();

        return () => {
            setSchemaDetails({});
        };
    //eslint-disable-next-line
    }, []);

    return (
        <div>
            <div>
                <Title level={3}>CSV Upload</Title>


            </div>

            <div>
                <Row>
                    <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                delay: 0.2,
                                duration: 0.5,
                                type: 'spring',
                                stiffness: 100,
                                damping: 10,
                            }}
                        >

                            <img
                                src={project.imageUrl}
                                alt="Project_Logo_Image"
                                width="100px"
                                style={{ resize: 'both', backgroundColor: 'transparent', objectFit: 'contain' }}
                                className="ButtonIcons"
                            />
                        </motion.div>
                    </Col>
                    <Col xs={20} sm={16} md={12} lg={8} xl={4}>
                    <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                delay: 0.4,
                                duration: 0.5,
                                type: 'spring',
                                stiffness: 100,
                                damping: 10,
                            }}
                        >
                        {project?.title}
                        </motion.div>
                    </Col>
                    <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                    <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                delay: 0.6,
                                duration: 0.5,
                                type: 'spring',
                                stiffness: 100,
                                damping: 10,
                            }}
                        >
                        {available ? <Text type="success">Upload verfügbar</Text> : <Text type="danger">Upload aktuell nicht möglich</Text>}
                        </motion.div>
                    </Col>
                </Row>
            </div>

            <div style={{
                alignContent: 'left',
                justifyContent: 'left',
                marginTop: '100px',
                textAlign: 'left'
            }}>
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: 0.8,
                        duration: 0.5,
                        type: 'spring',
                        stiffness: 100,
                        damping: 10,
                    }}
                    >
                        <Descriptions title="Upload Informationen" bordered column={2}>
                        <Descriptions.Item label="Projekt"> {project?.title} </Descriptions.Item>
                        <Descriptions.Item label="Benötigter Dateityp"> ".csv" </Descriptions.Item>
                        </Descriptions>
                    </motion.div>
            </div>

            <div style={{
                alignContent: 'left',
                justifyContent: 'left',
                marginTop: '20px',
                textAlign: 'left'
            }}>
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: 1,
                        duration: 0.5,
                        type: 'spring',
                        stiffness: 100,
                        damping: 10,
                    }}
                    >
                                {!available && (<>
                <Result
                    status="error"
                    title="Upload nicht verfügbar"
                    subTitle="Der Projektleiter muss noch ein Schema hinterlegen bzw. dieses hinterlegen lassen."
                />
            </>)}
                        <Button block size="large" type="primary" onClick={() => {
                            setShowUploadModal(true);
                        }}
                        disabled={!available}
                        >Upload starten</Button>
                    </motion.div>
            </div>



            <div style={{
                alignContent: 'left',
                justifyContent: 'left',
                marginTop: '20px',
                textAlign: 'left'
            }}>
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: 0.2,
                        duration: 0.1,
                        type: 'spring',
                        stiffness: 100,
                        damping: 10,
                    }}
                    >
                        <Title level={5}>Schema Informationen</Title>
                        <Text>Das Schema definiert die Struktur der Daten, die hochgeladen werden können. Die Felder müssen in der richtigen Reihenfolge und mit der richtigen Bezeichnung in der Datei hinterlegt sein.</Text>
                        <List
                            itemLayout="horizontal"
                            dataSource={fields}
                            renderItem={(item, i) => {
                                const delay = i * 0.15 + 1;
                                return ((
                                <motion.div
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{
                                        delay: delay,
                                        duration: 0.2,
                                        type: 'spring',
                                        stiffness: 100,
                                        damping: 10,
                                    }}
                                >
                                <List.Item>
                                    <List.Item.Meta
                                        title={`Feld Nr.: ${i + 1} - Feldname: ${item.technicalName}`}
                                        description={`Bennenung in der Datei: ${item.technicalName} | Typ: ${item.type} | Anzeigename: ${item?.displayName || 'Kein Anzeigename'}`}
                                    />
                                </List.Item>
                                </motion.div>
                            ))
                            }}
                        />
                    </motion.div>
            </div>

            <UploadCSVFileModal Open={showUploadModal} OnCancel={() => setShowUploadModal(false)} _Id={project?._id} />
        </div>
    )
};