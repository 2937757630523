import axios from "axios";
import { auth } from "../../firebase";
import { v4_setAPI } from "../api";
import { displaySuccessNotification } from "../../utils/messageServices/notificationService";
import _ from "lodash";
import { axiosInstance } from "../../axios/axiosInstance";
import { displayErrorModal } from "../../utils/messageServices/modalService";

const API_URL = v4_setAPI();

/**
 * 
 * @param {File} file 
 * @returns 
 */
export async function v4_get_latest_project_data(_id) {
    try {
        let response = null;
        response = await axiosInstance.get(`${API_URL}/stream/${_id}/dataset/latest`);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};


/**
 * 
 * @param {File} file 
 * @returns 
 */
export async function v4_updateData(data_id, project_id, update) {
    try {
        let response = null;
        response = await axiosInstance.patch(`${API_URL}/stream/${project_id}/data/edit`, update, { params: { 
            data_id: data_id
         } });
         console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};


//GET: Count of Substitutes: /:_project_id/collections/substitutes/count
export async function v4_get_substitute_count(_project_id) {
    try {
        let response = null;
        response = await axiosInstance.get(`${API_URL}/stream/${_project_id}/collections/substitutes/count`);
        return response.data?.count;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return 0;
    };
};

//GET: Substitutes / wwith Pagination: /:_project_id/collections/substitutes //?page=1&limit=10
export async function v4_get_substitutes(_project_id, page, limit) {
    try {
        let response = null;
        response = await axiosInstance.get(`${API_URL}/stream/${_project_id}/collections/substitutes`, { params: { 
            page: page,
            limit: limit
         } });
         console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};

//DELETE: Collection: /:_project_id/collections/substitutes/drop
export async function v4_drop_substitutes(_project_id) {
    try {
        let response = null;
        response = await axiosInstance.delete(`${API_URL}/stream/${_project_id}/collections/substitutes/drop`);
        console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Löschen der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};

//DELETE: document: /:_project_id/collections/substitute/:_substitute_id
export async function v4_delete_substitute(_project_id, _substitute_id) {
    try {
        let response = null;
        response = await axiosInstance.delete(`${API_URL}/stream/${_project_id}/collections/substitute/${_substitute_id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Löschen der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};

//POST: Reimport all data: /:_project_id/substitutes/reimport
export async function v4_reimport_substitutes(_project_id) {
    try {
        let response = null;
        response = await axiosInstance.post(`${API_URL}/stream/${_project_id}/substitutes/reimport`);
        console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Importieren der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};