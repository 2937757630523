import React, { useCallback, useMemo } from "react";
import { v4_getBackendLogs, v4_getCountOfBackendLogs } from "../../../../routing/v4_Routes/admin.routes";
import { Button, Divider, Modal, Table, Tag, Typography } from "antd";
import _ from "lodash";
import Search from "antd/es/input/Search";


export function BackendLogs() {

    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [level, setLevel] = React.useState(null); // null, 'info', 'warn', 'error'
    const [count, setCount] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [loading, setLoading] = React.useState(false);
    const [stackModalVisible, setStackModalVisible] = React.useState(false);
    const [stack, setStack] = React.useState('');

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await v4_getBackendLogs(page, pageSize, level);
            setData(response.docs);
            setCount(response.count);
        } catch (error) {
            console.error("Fehler beim Laden der Daten:", error);            
        }
        setLoading(false);
    }, [page, pageSize, level]);

    const getDataByPagination = async (newPage, newPageSize) => {
        try {
            setLoading(true);
            const response = await v4_getBackendLogs(newPage, newPageSize, level);
            setCount(response.length);
            setData(response.docs);
            setPage(newPage);
            setPageSize(newPageSize);
        } catch (error) {
            console.error("Fehler beim Laden der Daten:", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchData();

        return () => {
            setData([]);
        };
    }, [fetchData]);

    const columns = [
        {
            title: 'ID',
            dataIndex: '_id',
            key: '_id',
            width: 50,
        },
        {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
            width: 100,
            // filters: [
            //     { text: 'Info', value: 'info' },
            //     { text: 'Warn', value: 'warn' },
            //     { text: 'Error', value: 'error' },
            // ],
            // onFilter: (value, record) => _.lowerCase(record.level) === value,       
            render: (level) => {
                switch (_.lowerCase(level)) {
                    case 'info':
                        return <Tag color="blue">Info</Tag>;
                    case 'warn':
                        return <Tag color="orange">Warn</Tag>;
                    case 'error':
                        return <Tag color="red">Error</Tag>;
                    default:
                        return <Tag color="green">Unknown</Tag>;
                }
            },
        },
        {
            title: 'Beschreibung',
            dataIndex: 'description',
            key: 'description',
            width: 500,
        },
        {
            title: 'Timestamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: 200,
            render: (date) => {
                return new Date(date).toLocaleString();
            },
            // sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
        },
        {
            title: 'Stack',
            dataIndex: 'stack',
            key: 'stack',
            width: 200,
            render: (stack) => {
                return (
                    <Button
                        type="link"
                        onClick={() => {
                            setStack(stack);
                            setStackModalVisible(true);
                        }}
                    >
                        Stack anzeigen
                    </Button>
                );
            }
        }
    ]

    const onSearch = useCallback(async (value) => {
        try {
            setLoading(true);
            const response = await v4_getBackendLogs(page, pageSize, value);
            setData(response.docs);
            setCount(response.count);
        } catch (error) {
            console.error("Fehler beim Laden der Daten:", error);
        } finally {
        setLoading(false);
        }
    }, [page, pageSize]);


    return (
        <div>
            <Typography.Title>Backend Logs</Typography.Title>
            <div>
                <Divider
                    orientation="left"
                    style={{ color: "#333", fontWeight: "normal" }}
                >
                    Filter
                </Divider>
                <div style={{
                    textAlign: 'left',
                    marginBottom: '10px'
                }}>
                    <Search
                        placeholder="Suche nach Level"
                        onSearch={onSearch}
                        enterButton
                        loading={loading}
                        allowClear
                    />
                </div>
            </div>
            <div>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: count,
                        onChange: (page, pageSize) => {
                            setPage(page);
                            setPageSize(pageSize);
                            getDataByPagination(page, pageSize);
                        },
                    }}
                    scroll={{ x: 'max-content' }}
                    rowHoverable
                />
            </div>

            <div>
                <Modal
                    title="Stack"
                    open={stackModalVisible}
                    onOk={() => setStackModalVisible(false)}
                    onCancel={() => setStackModalVisible(false)}
                    destroyOnClose
                    width={"80%"}
                >
                    <pre>{
                        stack || 'Für diese Logmeldung wurde kein Stack angegeben.'
                    }</pre>
                </Modal>
            </div>
        </div>
    );
};