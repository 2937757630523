
import axios from "axios";
import { auth } from "../../firebase";
import { displaySuccessNotification } from "../../utils/messageServices/notificationService";
import { v4_setAPI } from "../api";
import _ from "lodash";
import { axiosInstance } from "../../axios/axiosInstance";
import { displayErrorModal } from "../../utils/messageServices/modalService";

const API_URL = v4_setAPI();


export async function v4_getProjectById(_id, populateUsers = false) {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.get(`${API_URL}/projects/${_id}`, { headers: { Authorization: `Bearer ${token}` } , params: { populateUsers } });
        if (!_.isEmpty(response)) {
            // displaySuccessNotification('Projekt erfolgreich geladen');
        };

        return response.data;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden des Projekts", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

export async function v4_getUsersByProjectId(_id, populateUsers = false) {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.get(`${API_URL}/projects/${_id}`, { headers: { Authorization: `Bearer ${token}` } , params: { populateUsers } });
        if (!_.isEmpty(response)) {
            displaySuccessNotification('Projekt erfolgreich geladen');
        };

        return response.data?.allowedUserId
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Benutzer", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

export async function v4_get_projects() {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.get(`${API_URL}/projects/projects/getall`, { headers: { Authorization: `Bearer ${token}` } });

        if (!_.isEmpty(response)) {
            displaySuccessNotification('Projekte erfolgreich geladen');
        };

        return response.data;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden der Projekte", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

export async function v4_createCsvSchemaData(_project_id, body) {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.post(`${API_URL}/projects/${_project_id}/csv/schema`, body, { headers: { Authorization: `Bearer ${token}` } });

        if (!_.isEmpty(response)) {
            displaySuccessNotification('Schema erfolgreich erstellt');
        };

        return response.data;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Erstellen des CSV-Schemas", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

export async function v4_update_schema_details_in_project(_project_id) {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.patch(`${API_URL}/projects/${_project_id}/csv/schema/set/csv-properties`, { headers: { Authorization: `Bearer ${token}` } });

        if (!_.isEmpty(response)) {
            displaySuccessNotification('Schema erfolgreich erstellt');
        };

        return response.data;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Erstellen des CSV-Schemas", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

export async function v4_getCsvSchemaData(_project_id) {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.get(`${API_URL}/projects/${_project_id}/csv/schema`, { headers: { Authorization: `Bearer ${token}` } });

        if (!_.isEmpty(response)) {
            displaySuccessNotification('Schema erfolgreich geladen');
        };

        return response.data;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Laden des CSV-Schemas", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

export async function v4_syncSchema(_project_id) {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.get(`${API_URL}/projects/${_project_id}/csv/schema/synchronize`, { headers: { Authorization: `Bearer ${token}` } });

        if (!_.isEmpty(response)) {
            displaySuccessNotification(`Status: ${response.status} - ${response.statusText}`);
        };

        return;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Synchronisieren des CSV-Schemas", error?.response?.data?.error, error?.response?.data?.stack)
        return;
    };
};

export async function v4_updateCsvSchemaData(_schema_id, body) {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.patch(`${API_URL}/projects/csv/schema/${_schema_id}`, body, { headers: { Authorization: `Bearer ${token}` } });
        
        if (!_.isEmpty(response)) {
            displaySuccessNotification('Schema erfolgreich aktualisiert');
        };

        return response.data;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Aktualisieren des CSV-Schemas", error?.response?.data?.error, error?.response?.data?.stack)
    };
};

export async function v4_downloadSampleCsv(_schema_id) {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.get(`${API_URL}/projects/csv/schema/${_schema_id}/sample`, {
            headers: { Authorization: `Bearer ${token}` },
            responseType: 'blob' // Setze den responseType auf 'blob'
        });

        // Create a Blob from the response data
        const file = new Blob([response.data], { type: 'text/csv' });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Create an anchor element and trigger the download
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `sample_${_schema_id}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(fileURL); // Clean up the object URL

    } catch (error) {
        console.error('Error downloading the CSV file:', error);
        displayErrorModal("Fehler beim Herunterladen der CSV-Datei", error?.response?.data?.error, error?.response?.data?.stack)
    }
}

export async function v4_setSollKpi(_id, body) {
    try {
        const response = await axiosInstance.patch(`${API_URL}/projects/${_id}/sollKpi`, body, { headers: { Operation: `Update soll-values` } });

        if (!_.isEmpty(response)) {
            displaySuccessNotification('Sollwerte erfolgreich geändert');
        };

        return response.data;
    } catch (error) {
        console.log(error);
        displayErrorModal("Fehler beim Setzen der Sollwerte", error?.response?.data?.error, error?.response?.data?.stack)
    };
};