import * as React from 'react';
import { useState } from 'react';
import { Button, Modal, Select, Space, Typography, Upload } from 'antd';
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { v4_upload_timeboard_data } from '../../../routing/v4_Routes/timeboard.routes';
import _ from 'lodash';
import { useEffect } from 'react';
import { v4_get_projects } from '../../../routing/v4_Routes/project.routes';

export default function TimeBoardUploadComponent(props) {
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [projects, setProjects] = useState([]);
    const [uploadOptions, setUploadOptions] = useState({});

    const reset = () => {
        setFileList([]);
        setLoading(false);
        setUploading(false);
        setUploadOptions({});
    };

    const fetchProjects = async () => {
        const data = await v4_get_projects();
        if (!_.isEmpty(data)) {
            //#return only "_id, costCentre, title"
            const reduced = data.map((project) => {
                return {
                    _id: project._id,
                    costCentre: project.costCentre,
                    title: project.title
                };
            });
            setProjects(reduced);
        };
    };

    useEffect(() => {
        fetchProjects();

        return () => {
            setProjects([]);
        };
    }, []);

    const onChangeCostCentre = (value) => {
        if (_.isEmpty(value)) {
            setUploadOptions({});
            return;
        };
        const project = projects.find((project) => project._id === value);
        setUploadOptions({ project });
    };


    const handleUpload = async () => {
        setUploading(true);
        setLoading(true);

        if (fileList.length === 0) {
            setLoading(false);
            setUploading(false);
            return;
        };
        
        await v4_upload_timeboard_data(fileList[0], uploadOptions);

        handleCancel();
    };

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        maxCount: 1,
        fileTypes: ["csv"],
        accept: ".csv",
    };

    const handleCancel = () => {
        reset();
        props.handleCancel();
    };


    let content = (
        <div>

            <div>
                <Modal
                    title={`Timeboard Tabelle hochladen`}
                    centered
                    open={props.open}
                    onCancel={handleCancel}
                    destroyOnClose={true}
                    width={"40%"}
                    footer={[
                        <Button type='primary' onClick={handleCancel} key={1}>Abbrechen</Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={handleUpload}>
                            Hochladen
                        </Button>
                    ]}
                >
                    <Typography.Text>
                        Beim hochladen der Timeboard Tabelle muss künftig eine Kostenstelle angegeben werden. Bitte wählen Sie das Projekt aus, zu dem die Tabelle gehört.
                    </Typography.Text>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "1rem"

                    }}>
                        <Space>
                            <Upload
                                maxCount={1}
                                multiple={false}
                                {...uploadProps}>
                                <Button
                                    type="primary"
                                    loading={loading}
                                    icon={uploading ? <LoadingOutlined /> : <UploadOutlined />}
                                >Datei auswählen</Button>
                            </Upload>
                            <Select
                                placeholder="Bitte Projekt auswählen"
                                style={{ width: 200 }}
                                loading={loading}
                                options={projects.map((project) => {
                                    return {
                                        label: `${project?.costCentre ?? "???"} | ${project?.title}`,
                                        value: project._id,
                                        disabled: _.isEmpty(project?.costCentre)
                                    };
                                })}
                                onChange={onChangeCostCentre}
                                allowClear
                            />
                        </Space>
                        <p><strong>Bitte jede Datei einzeln hochladen.</strong></p>
                    </div>

                </Modal>
            </div>

        </div>

    )

    return content;
};