import { util_ProjectDetailsUpdate } from "../updaterUtils/projectUpdateUtil";


export const p_projectPropChange = async (value, fieldName, project, callback) => {
    const { _id } = project;

    console.log("Changing value of project property: ", fieldName, value);
    console.log("Project ID: ", _id);
    await util_ProjectDetailsUpdate(_id, fieldName, value)

    //Callback
    if (callback) return callback();

    return;
};