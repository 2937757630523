import React from 'react';
import { Route } from 'react-router-dom';
import { MainProject } from "../../components/pages/project/MainProject.page";

const projectAdminPages = [
    <Route path="/p/:_id/details/settings" element={<MainProject />} />
];

export default projectAdminPages;

